const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

function assetLink(asset) {
  return `./img/gallery/${asset}`;
}
const photos = [
  {
    asset: "01.webp",
    width: 1200,
    height: 793,
    alt: "Life At Webcrown",
  },
  {
    asset: "02.webp",
    width: 1200,
    height: 846,
    alt: "Life At Webcrown",
  },
  {
    asset: "03.webp",
    width: 1200,
    height: 675,
    alt: "Life At Webcrown",
  },
  {
    asset: "04.webp",
    width: 1012,
    height: 583,
    alt: "Life At Webcrown",
  },
  {
    asset: "05.webp",
    width: 1200,
    height: 941,
    alt: "Life At Webcrown",
  },
  {
    asset: "06.webp",
    width: 1116,
    height: 904,
    alt: "Life At Webcrown",
  },
  {
    asset: "07.webp",
    width: 1200,
    height: 900,
    alt: "Life At Webcrown",
  },
  {
    asset: "08.webp",
    width: 1200,
    height: 900,
    alt: "Life At Webcrown",
  },
  {
    asset: "09.webp",
    width: 1200,
    height: 900,
    alt: "Life At Webcrown",
  },
  {
    asset: "10.webp",
    width: 1200,
    height: 904,
    alt: "Life At Webcrown",
  },
  {
    asset: "11.webp",
    width: 1200,
    height: 1600,
    alt: "Life At Webcrown",
  },
  {
    asset: "12.webp",
    width: 675,
    height: 1200,
    alt: "Life At Webcrown",
  },
  {
    asset: "13.webp",
    width: 1200,
    height: 675,
    alt: "Life At Webcrown",
  },
  {
    asset: "14.webp",
    width: 675,
    height: 1200,
    alt: "Life At Webcrown",
  },
  {
    asset: "15.webp",
    width: 675,
    height: 1200,
    alt: "Life At Webcrown",
  },
  {
    asset: "16.webp",
    width: 675,
    height: 1200,
    alt: "Life At Webcrown",
  },
  {
    asset: "17.webp",
    width: 675,
    height: 1200,
    alt: "Life At Webcrown",
  },
  {
    asset: "18.webp",
    width: 1200,
    height: 819,
    alt: "Life At Webcrown",
  },
  
].map(({ asset, alt, width, height }) => ({
  src:  assetLink(asset, width),
  alt,
  width,
  height,
  srcSet: breakpoints.map((breakpoint) => ({
    src: assetLink(asset, breakpoint),
    width: breakpoint,
    height: Math.round((height / width) * breakpoint),
  })),
}));

export default photos;