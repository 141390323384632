import SubBanner from "@/common/subbanner";
import { motion, useScroll, useTransform } from "framer-motion";
import { useRef, React, useContext, useEffect, useState } from "react";
import { AnimatedCursorContext } from "@/cursor/animatedcursormanager";
import Paragraph from "@/description/paragraph";
import { Helmet, HelmetProvider } from "react-helmet-async";
export function Career() {
  const [currentURL, setCurrentURL] = useState(window.location.href);
  useEffect(() => {
    setCurrentURL(window.location.href);
    const mask = document.querySelector('.maskimg');

    const onMouseMove = (pos) => {
      let x = parseInt(pos.clientX / window.innerWidth * 100);
      let y = parseInt(pos.clientY / window.innerHeight * 100);

      mask.style.setProperty('--mouse-x', x + '%');
      mask.style.setProperty('--mouse-y', y + '%');
    };

    document.addEventListener('pointermove', onMouseMove);

    return () => {
      document.removeEventListener('pointermove', onMouseMove);
    };
  }, []);
  const containerRef = useRef();
  useScroll(containerRef, { layoutEffect: false });
  const { cursorStyleHandler } = useContext(AnimatedCursorContext);


  const imgEnter = () => {
    cursorStyleHandler("none");
  };

  const imgLeave = () => {
    cursorStyleHandler("default");
  };

  const LaravelDeveloper = useRef(null);
  const { scrollYProgress: LaravelScrollYProgress } = useScroll({
    target: LaravelDeveloper,
    offset: ["start 0.9", "end 0.9"]
  });
  const LaravelX = useTransform(LaravelScrollYProgress, [0, 1], ['-100%', '0%']);
  const LaravelY = useTransform(LaravelScrollYProgress, [0, 1], ['100%', '0%']);
  const Laravelrotate = useTransform(LaravelScrollYProgress, [0, 1], ['-45deg', '0deg']);
  const Laravelopacity = useTransform(LaravelScrollYProgress, [0, 1], [0, 1]);

  const QualityAnalyst = useRef(null);
  const { scrollYProgress: QualityScrollYProgress } = useScroll({
    target: QualityAnalyst,
    offset: ["start 0.9", "end 0.9"]
  });
  const QualityX = useTransform(QualityScrollYProgress, [0, 1], ['100%', '0%']);
  const QualityY = useTransform(QualityScrollYProgress, [0, 1], ['100%', '0%']);
  const Qualityrotate = useTransform(QualityScrollYProgress, [0, 1], ['45deg', '0deg']);
  const Qualityopacity = useTransform(QualityScrollYProgress, [0, 1], [0, 1]);


  const WebDesigner = useRef(null);
  const { scrollYProgress: DesignerScrollYProgress } = useScroll({
    target: WebDesigner,
    offset: ["start 0.9", "end 0.9"]
  });
  const DesignerX = useTransform(DesignerScrollYProgress, [0, 1], ['-100%', '0%']);
  const DesignerY = useTransform(DesignerScrollYProgress, [0, 1], ['100%', '0%']);
  const Designerrotate = useTransform(DesignerScrollYProgress, [0, 1], ['-45deg', '0deg']);
  const Designeropacity = useTransform(DesignerScrollYProgress, [0, 1], [0, 1]);


  const AndroidDeveloper = useRef(null);
  const { scrollYProgress: AndroidScrollYProgress } = useScroll({
    target: AndroidDeveloper,
    offset: ["start 0.9", "end 0.9"]
  });
  const AndroidX = useTransform(AndroidScrollYProgress, [0, 1], ['100%', '0%']);
  const AndroidY = useTransform(AndroidScrollYProgress, [0, 1], ['100%', '0%']);
  const Androidrotate = useTransform(AndroidScrollYProgress, [0, 1], ['45deg', '0deg']);
  const Androidopacity = useTransform(AndroidScrollYProgress, [0, 1], [0, 1]);


  const ceoleftRef = useRef(null);
  const ceorightRef = useRef(null);

  const { scrollYProgress: ceoleftScrollYProgress } = useScroll({
    target: ceoleftRef,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXleftceo = useTransform(ceoleftScrollYProgress, [0, 1], ['0%', '10%']);
  const scaleleftceo = useTransform(ceoleftScrollYProgress, [0, 1], [0.6, 1]);

  const { scrollYProgress: ceorightScrollYProgress } = useScroll({
    target: ceorightRef,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXrightceo = useTransform(ceorightScrollYProgress, [0, 1], ['0%', '10%']);
  const scalerightceo = useTransform(ceorightScrollYProgress, [0, 1], [0.6, 1]);
// row 1
  const leftRef = useRef(null);
  const rightRef = useRef(null);
  const centerRef = useRef(null);
  const middleRef = useRef(null);
  const { scrollYProgress: leftScrollYProgress } = useScroll({
    target: leftRef,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXleft = useTransform(leftScrollYProgress, [0, 1], ['0%', '-10%']);
  const rotateleft = useTransform(leftScrollYProgress, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: centerScrollYProgress } = useScroll({
    target: centerRef,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXcenter = useTransform(centerScrollYProgress, [0, 1], ['0%', '-50%']);
  const rotatecenter = useTransform(centerScrollYProgress, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: middleScrollYProgress } = useScroll({
    target: middleRef,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXmiddle = useTransform(middleScrollYProgress, [0, 1], ['0%', '-25%']);
  const rotatemiddle = useTransform(middleScrollYProgress, [0, 1], ['0deg', '0deg']);
  const { scrollYProgress: rightScrollYProgress } = useScroll({
    target: rightRef,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXright = useTransform(rightScrollYProgress, [0, 1], ['0%', '-70%']);
  const rotateright = useTransform(rightScrollYProgress, [0, 1], ['0deg', '0deg']);

// row 2

  const leftRef2 = useRef(null);
  const rightRef2 = useRef(null);
  const centerRef2 = useRef(null);
  const middleRef2 = useRef(null);
  const { scrollYProgress: leftScrollYProgress2 } = useScroll({
    target: leftRef2,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXleft2 = useTransform(leftScrollYProgress2, [0, 1], ['0%', '-10%']);
  const rotateleft2 = useTransform(leftScrollYProgress2, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: centerScrollYProgress2 } = useScroll({
    target: centerRef2,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXcenter2 = useTransform(centerScrollYProgress2, [0, 1], ['0%', '-50%']);
  const rotatecenter2 = useTransform(centerScrollYProgress2, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: middleScrollYProgress2 } = useScroll({
    target: middleRef2,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXmiddle2 = useTransform(middleScrollYProgress2, [0, 1], ['0%', '-25%']);
  const rotatemiddle2 = useTransform(middleScrollYProgress2, [0, 1], ['0deg', '0deg']);
  const { scrollYProgress: rightScrollYProgress2 } = useScroll({
    target: rightRef2,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXright2 = useTransform(rightScrollYProgress2, [0, 1], ['0%', '-70%']);
  const rotateright2 = useTransform(rightScrollYProgress2, [0, 1], ['0deg', '0deg']);

  // row 3
  const leftRef3 = useRef(null);
  const rightRef3 = useRef(null);
  const centerRef3 = useRef(null);
  const middleRef3 = useRef(null);
  const { scrollYProgress: leftScrollYProgress3 } = useScroll({
    target: leftRef3,
    offset: ["start 0.9", "end 0.1"]
  });

  const translateXleft3 = useTransform(leftScrollYProgress3, [0, 1], ['0%', '-10%']);
  const rotateleft3 = useTransform(leftScrollYProgress3, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: centerScrollYProgress3 } = useScroll({
    target: centerRef3,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXcenter3 = useTransform(centerScrollYProgress3, [0, 1], ['0%', '-50%']);
  const rotatecenter3 = useTransform(centerScrollYProgress3, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: middleScrollYProgress3 } = useScroll({
    target: middleRef3,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXmiddle3 = useTransform(middleScrollYProgress3, [0, 1], ['0%', '-25%']);
  const rotatemiddle3 = useTransform(middleScrollYProgress3, [0, 1], ['0deg', '0deg']);
  const { scrollYProgress: rightScrollYProgress3 } = useScroll({
    target: rightRef3,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXright3 = useTransform(rightScrollYProgress3, [0, 1], ['0%', '-70%']);
  const rotateright3 = useTransform(rightScrollYProgress3, [0, 1], ['0deg', '0deg']);

// row 4
  const leftRef4 = useRef(null);
  const rightRef4 = useRef(null);
  const centerRef4 = useRef(null);
  const middleRef4 = useRef(null);
  const { scrollYProgress: leftScrollYProgress4 } = useScroll({
    target: leftRef4,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXleft4 = useTransform(leftScrollYProgress4, [0, 1], ['0%', '-10%']);
  const rotateleft4 = useTransform(leftScrollYProgress4, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: centerScrollYProgress4 } = useScroll({
    target: centerRef4,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXcenter4 = useTransform(centerScrollYProgress4, [0, 1], ['0%', '-50%']);
  const rotatecenter4 = useTransform(centerScrollYProgress4, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: middleScrollYProgress4 } = useScroll({
    target: middleRef4,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXmiddle4 = useTransform(middleScrollYProgress4, [0, 1], ['0%', '-25%']);
  const rotatemiddle4 = useTransform(middleScrollYProgress4, [0, 1], ['0deg', '0deg']);
  const { scrollYProgress: rightScrollYProgress4 } = useScroll({
    target: rightRef4,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXright4 = useTransform(rightScrollYProgress4, [0, 1], ['0%', '-70%']);
  const rotateright4 = useTransform(rightScrollYProgress4, [0, 1], ['0deg', '0deg']);


// row 5

  const leftRef5 = useRef(null);
  const rightRef5 = useRef(null);
  const centerRef5 = useRef(null);
  const middleRef5 = useRef(null);
  const { scrollYProgress: leftScrollYProgress5 } = useScroll({
    target: leftRef5,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXleft5 = useTransform(leftScrollYProgress5, [0, 1], ['0%', '-10%']);
  const rotateleft5 = useTransform(leftScrollYProgress5, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: centerScrollYProgress5 } = useScroll({
    target: centerRef5,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXcenter5 = useTransform(centerScrollYProgress5, [0, 1], ['0%', '-50%']);
  const rotatecenter5 = useTransform(centerScrollYProgress5, [0, 1], ['0deg', '0deg']);

  const { scrollYProgress: middleScrollYProgress5 } = useScroll({
    target: middleRef5,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXmiddle5 = useTransform(middleScrollYProgress5, [0, 1], ['0%', '-25%']);
  const rotatemiddle5 = useTransform(middleScrollYProgress5, [0, 1], ['0deg', '0deg']);
  const { scrollYProgress: rightScrollYProgress5 } = useScroll({
    target: rightRef5,
    offset: ["start 0.9", "end 0.1"]
  });
  const translateXright5 = useTransform(rightScrollYProgress5, [0, 1], ['0%', '-70%']);
  const rotateright5 = useTransform(rightScrollYProgress5, [0, 1], ['0deg', '0deg']);

  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>Career</title>
          <meta name="title" content="Career - Web Crown" />
          <meta name="og:url" content={currentURL} />
          <meta name="og:image" content="./img/logo.svg" />
        </Helmet>
      </HelmetProvider>
      <motion.div
        className="h-full relative z-0"
        initial={{ y: "-200vh" }}
        animate={{ y: "0%" }}
        transition={{ duration: 1 }}
      >

        <SubBanner
          title="Oppurtuinity for your Growth"
          content="At Web Crown, we are passionate about innovation and excellence. Our mission is to have a great Impact on the Industry, and we are driven by our core values of integrity, collaboration, and customer focus. Join us and be part of a dynamic team that values creativity, commitment, and growth."
        />
        
      </motion.div>
      <section className="sec-padding px-0 !pt-0 relative">
          <div className="mx-auto relative h-full">
            <motion.div className="absolute inset-0 flex items-center container z-10 ">
              <p className="text-[5vw] sm:text-[5vw] md:text-[4vw] text-center">
                <span className="text-red-500">WEBCROWN®</span> has proudly empowered companies to thrive in the corporate landscape.
              </p>
            </motion.div>

            <div className="absolute left-0 right-0">
              <div className="opacity-20">
                <img src="./img/about_mask_img.webp" className="w-full" />
              </div>
            </div>
            <div className="maskimg" onMouseEnter={imgEnter} onMouseLeave={imgLeave}>
              <div className="">
                <img src="./img/about_mask_img_hover.webp" className="w-full" />
              </div>
            </div>
          </div>
        </section>
        <section className="sec-padding px-0 !pt-0 relative z-0">
          <div className="mx-auto relative container">
            <div className="py-10 text-5xl">
              <Paragraph paragraph="Our team's core strength is" />
              <Paragraph paragraph=" rooted in our" />
              <Paragraph paragraph="relentless passion." />
            </div>
            <div className="pb-10 common-fonts box-right">
              <Paragraph paragraph="At the heart of" />
              <Paragraph paragraph="our team's success is" />
              <Paragraph paragraph="our adventurous spirit." />
            </div>
          </div>
          
        </section>
        <div className="mx-auto relative container z-1">
            <div>
              <span className="text-4xl font-thin font-anta uppercase tracking-[10px] text-center block mb-9">Now Hiring</span>
              <div className="grid grid-cols-1 md:grid-cols-2 md:grid-cols-2 gap-10">
                <motion.div className="job-card"
                  ref={LaravelDeveloper}
                  style={{
                    transformOrigin: 'left',
                    translateX: LaravelX,
                    translateY: LaravelY,
                    rotate: Laravelrotate,
                    opacity: Laravelopacity
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 1
                  }}
                >
                  <a href="https://docs.google.com/forms/d/1HARV8gDpx_AA2F35P_4r-h5x7vXqMkIVgmCnUAO7k2A/edit?pli=1" target="_blank">
                    <h3>Laravel Developer</h3>
                    <p className="tag">
                      <span>Min. 1 Year Experience</span>
                      <span>Full Time Job</span>
                    </p>
                    <span className="apply-hover">Apply Now</span>
                  </a>
                </motion.div>
                <motion.div className="job-card"
                  ref={QualityAnalyst}
                  style={{
                    transformOrigin: 'right',
                    translateX: QualityX,
                    translateY: QualityY,
                    rotate: Qualityrotate,
                    opacity: Qualityopacity
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 1
                  }}
                >
                  <a href="https://docs.google.com/forms/d/1HARV8gDpx_AA2F35P_4r-h5x7vXqMkIVgmCnUAO7k2A/edit?pli=1" target="_blank">
                    <h3>Quality Analyst</h3>
                    <p className="tag">
                      <span>Min. 1 Year Experience</span>
                      <span>Full Time Job</span>
                    </p>
                    <span className="apply-hover">Apply Now</span>
                  </a>
                </motion.div>
                <motion.div className="job-card"
                  ref={WebDesigner}
                  style={{
                    transformOrigin: 'left',
                    translateX: DesignerX,
                    translateY: DesignerY,
                    rotate: Designerrotate,
                    opacity: Designeropacity
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 1
                  }}
                >
                  <a href="https://docs.google.com/forms/d/1HARV8gDpx_AA2F35P_4r-h5x7vXqMkIVgmCnUAO7k2A/edit?pli=1" target="_blank">
                    <h3>Web Designer</h3>
                    <p className="tag">
                      <span>Min. 1 Year Experience</span>
                      <span>Full Time Job</span>
                    </p>
                    <span className="apply-hover">Apply Now</span>
                  </a>
                </motion.div>
                <motion.div className="job-card"
                  ref={AndroidDeveloper}
                  style={{
                    transformOrigin: 'right',
                    translateX: AndroidX,
                    translateY: AndroidY,
                    rotate: Androidrotate,
                    opacity: Androidopacity
                  }}
                  transition={{
                    type: "spring",
                    stiffness: 500,
                    damping: 1
                  }}
                >
                  <a href="https://docs.google.com/forms/d/1HARV8gDpx_AA2F35P_4r-h5x7vXqMkIVgmCnUAO7k2A/edit?pli=1" target="_blank">
                    <h3>Android Developer</h3>
                    <p className="tag">
                      <span>Min. 1 Year Experience</span>
                      <span>Full Time Job</span>
                    </p>
                    <span className="apply-hover">Apply Now</span>
                  </a>
                </motion.div>
              </div>
            </div>
          </div>
        <section className="sec-padding px-0 !pb-0 relative ">
          <div className="mx-auto relative container">
            <div className="py-10 text-5xl">
              <Paragraph paragraph="Our team's strength " />
              <Paragraph paragraph="lies in the heart of" />
              <Paragraph paragraph="our bold ambition." />
            </div>
            <div className="pb-10 common-fonts box-right">
              <Paragraph paragraph="The essence of our team's" />
              <Paragraph paragraph="power is fueled by our" />
              <Paragraph paragraph="fearless drive" />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 gap-4 lg:gap-16 justify-center mb-40">
              <motion.div className="col ceo-team-box"
                ref={ceoleftRef}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXleftceo,
                  scale: scaleleftceo,
                }}
              >
                <div className="img block lg:flex items-center gap-10">
                  <img src="./img/team/AjayMakwana.webp" alt="Ajay Makwana Founder" className="w-full" />
                  <div className="name">
                    <h3>Ajay Makwana</h3>
                    <span>Founder & CEO</span>
                  </div>
                </div>

              </motion.div>
              <motion.div className="col ceo-team-box"
                ref={ceorightRef}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXrightceo,
                  scale: scalerightceo,
                }}
              >
                <div className="img block lg:flex items-center gap-10">
                  <img src="./img/team/YogitaMakwana.webp" alt="Yogita Makwana Co-Founder" className="w-full" />
                  <div className="name">
                    <h3>Yogita Makwana</h3>
                    <span>Co-Founder</span>
                  </div>
                </div>
              </motion.div>
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-16 justify-center">
           {/* row 1 */}
              <motion.div className="col team-box"
                ref={leftRef}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXleft,
                  rotate: rotateleft,
                }}
              >
                <div className="img">
                  <img src="./img/team/MilanSangani.webp" alt="Milan Sangani Web Developer" className="w-full" />

                  <div className="name">
                    <h3>Milan Sangani</h3>
                    <span>Sr. Web Developer</span>
                  </div>
                </div>
              </motion.div>
              <motion.div className="col team-box"
                ref={centerRef}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXcenter,
                  rotate: rotatecenter,
                }}
              >
                <div className="img">
                  <img src="./img/team/BharatBhatiya.webp" alt="Bharat Bhatiya Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Bharat Bhatiya</h3>
                    <span>Sr. Web Developer</span>
                  </div>
                </div>
              </motion.div>
              <motion.div className="col team-box"
                ref={middleRef}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXmiddle,
                  rotate: rotatemiddle,
                }}
              >
                <div className="img">
                  <img src="./img/team/VivekThanki.webp" alt="Vivek Thanki Android & React Native Developer" className="w-full" />
                  <div className="name">
                    <h3>Vivek Thanki</h3>
                    <span>Sr. Android & React Native Developer</span>
                  </div>
                </div>
              </motion.div>
              <motion.div className="col team-box"
                ref={rightRef}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXright,
                  rotate: rotateright,
                }}
              >
                <div className="img">
                  <img src="./img/team/SugnitTala.webp" alt="Sugnit Tala Web Designer" className="w-full" />
                  <div className="name">
                    <h3>Sugnit Tala</h3>
                    <span>Web Designer</span>
                  </div>
                </div>
              </motion.div>
              {/* row 2 */}
              <motion.div className="col team-box"
                ref={leftRef2}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXleft2,
                  rotate: rotateleft2,
                }}
              >
                <div className="img">
                  <img src="./img/team/RiteshPatadiya.webp" alt="Ritesh Patadiya Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Ritesh Patadiya</h3>
                    <span>Sr. Web Developer</span>
                  </div>
                </div>

              </motion.div>
              <motion.div className="col team-box"
                ref={centerRef2}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXcenter2,
                  rotate: rotatecenter2,
                }}
              >
                <div className="img">
                  <img src="./img/team/VidhiBhatt.webp" alt="Vidhi Bhatt Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Vidhi Bhatt</h3>
                    <span>Jr. Web Developer</span>
                  </div>
                </div>

              </motion.div>
              <motion.div className="col team-box"
                ref={middleRef2}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXmiddle2,
                  rotate: rotatemiddle2,
                }}
              >
                <div className="img">
                <img src="./img/team/MonikaSakariya.webp" alt="Monika Sakariya Human Resources" className="w-full" />
                  <div className="name">
                    <h3>Monika Sakariya</h3>
                    <span>Human Resources (HR)</span>
                  </div>
                </div>

              </motion.div>
              <motion.div className="col team-box"
                ref={rightRef2}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXright2,
                  rotate: rotateright2,
                }}
              >
                <div className="img">
                  <img src="./img/team/RuchiKadecha.webp" alt="Ruchi Kadecha Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Ruchi Kadecha</h3>
                    <span>Jr. Web Developer</span>
                  </div>
                </div>
              </motion.div>
              {/* row 3 */}
              <motion.div className="col team-box"
                ref={leftRef3}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXleft3,
                  rotate: rotateleft3,
                }}
              >
                <div className="img">
                  <img src="./img/team/SavanBalasara.webp" alt="Savan Balasara QA Tester" className="w-full" />
                  <div className="name">
                    <h3>Savan Balasara</h3>
                    <span>Sr. QA Tester</span>
                  </div>
                </div>
              </motion.div>
              <motion.div className="col team-box"
                ref={centerRef3}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXcenter3,
                  rotate: rotatecenter3,
                }}
              >
                <div className="img">
                  <img src="./img/team/JayGanatra.webp" alt="Jay Ganatra Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Jay Ganatra</h3>
                    <span>Sr. Web Developer</span>
                  </div>
                </div>
              </motion.div>
              <motion.div className="col team-box"
                ref={middleRef3}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXmiddle3,
                  rotate: rotatemiddle3,
                }}
              >
                <div className="img">
                  <img src="./img/team/ParamHumbal.webp" alt="Param Humbal Server Administrator" className="w-full" />
                  <div className="name">
                    <h3>Param Humbal</h3>
                    <span>DevOps Engineer</span>
                  </div>
                </div>
              </motion.div>
              <motion.div className="col team-box"
                ref={rightRef3}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXright3,
                  rotate: rotateright3,
                }}
              >
                <div className="img">
                  <img src="./img/team/DipaliJadav.webp" alt="Dipali Jadav Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Dipali Jadav</h3>
                    <span>Jr. Web Developer</span>
                  </div>
                </div>
              </motion.div>
              {/* row 4 */}
              <motion.div className="col team-box"
                ref={leftRef4}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXleft4,
                  rotate: rotateleft4,
                }}
              >
                <div className="img">
                  <img src="./img/team/VivekGondhiya.webp" alt="Vivek Gondhiya Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Vivek Gondhiya</h3>
                    <span>Sr. Web Developer</span>
                  </div>
                </div>
              </motion.div>
              <motion.div className="col team-box"
                ref={centerRef4}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXcenter4,
                  rotate: rotatecenter4,
                }}
              >
                <div className="img">
                  <img src="./img/team/MahekKundal.webp" alt="Mahek Kundal Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Mahek Kundal</h3>
                    <span>Jr. Web Developer</span>
                  </div>
                </div>
              </motion.div>
              <motion.div className="col team-box"
                ref={middleRef4}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXmiddle4,
                  rotate: rotatemiddle4,
                }}
              >
                <div className="img">
                  <img src="./img/team/JaydeepChaudhary.webp" alt="JaydeepChaudhary QA Tester" className="w-full" />
                  <div className="name">
                    <h3>Jaydeep Chaudhary</h3>
                    <span>QA Tester</span>
                  </div>
                </div>
              </motion.div>
              <motion.div className="col team-box"
                ref={rightRef4}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXright4,
                  rotate: rotateright4,
                }}
              >
                <div className="img">
                  <img src="./img/team/PranaliLakhalani.webp" alt="Pranali Lakhalani Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Pranali Lakhalani</h3>
                    <span>Web Developer</span>
                  </div>
                </div>
              </motion.div>

 {/* row 5 */}

              <motion.div className="col team-box"
                ref={leftRef5}
                style={{
                  transformOrigin: 'center',
                  translateY: translateXleft5,
                  rotate: rotateleft5,
                }}
              >
                <div className="img">
                  <img src="./img/team/ParasMajethiya.webp" alt="Paras Majethiya Web Developer" className="w-full" />
                  <div className="name">
                    <h3>Paras Majethiya</h3>
                    <span>Web Developer</span>
                  </div>
                </div>
              </motion.div>


               {/*            
               <motion.div className="col"
                  ref={rightRef4}
                  style={{
                    transformOrigin: 'center',
                    translateY: translateXright4,
                    rotate: rotateright4,
                  }}
                >
                  <div className="img">

                  </div>
                  <div className="name">

                  </div>
                </motion.div> */}
            </div>
          </div>
        </section>
    </>
  )
}
export default Career;