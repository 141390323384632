import SubBanner from "@/common/subbanner";
import TitleAnimation from "@/common/titleanimation";
import { motion } from "framer-motion";
import { useRef, React, useState, useEffect } from "react";
import gsap from 'gsap';
import { Helmet, HelmetProvider } from "react-helmet-async";

import { RowsPhotoAlbum } from 'react-photo-album';
import 'react-photo-album/rows.css';

import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

// import optional lightbox plugins
import Fullscreen from 'yet-another-react-lightbox/plugins/fullscreen';
import Slideshow from 'yet-another-react-lightbox/plugins/slideshow';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import 'yet-another-react-lightbox/plugins/thumbnails.css';

import photos from '../component/photos';
export function Chronicles() {
  const containerRef = useRef();
  const background = useRef(null);
  const [index, setIndex] = useState(-1);
  const setBackground = (isActive) => {
    gsap.to(background.current, { opacity: isActive ? 0.8 : 0 })
  }

  const [currentURL, setCurrentURL] = useState(window.location.href);
  useEffect(() => {
    setCurrentURL(window.location.href);
  }, []);

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <title>Contact Us</title>
          <meta name="title" content="Contact Us - Web Crown" />
          <meta name="og:url" content={currentURL} />
          <meta name="og:image" content="./img/logo.svg" />
        </Helmet>
      </HelmetProvider>

      <motion.div
        className="h-full relative z-0"
        initial={{ y: "-200vh" }}
        animate={{ y: "0%" }}
        transition={{ duration: 1 }}
      >
        <SubBanner
          title="Celebrating Moments, Building Memories"
          content="Empowering businesses with transformative tech solutions. Driving innovation through agile development and strategic branding. Your partner for navigating the digital landscape with confidence."
        />
      
      </motion.div>
      <div className='container'>
          <section className="relative z-1">
            <div className="relative text-center w-fit mx-auto px-10">
              <TitleAnimation text1="Life At" text2="Webcrown" />
            </div>
            <div className="gallery-box mt-16">
              <RowsPhotoAlbum
                  photos={photos}
                  targetRowHeight={600}
                  onClick={({ index }) => setIndex(index)}
              />
            </div>
            <Lightbox
              slides={photos}
              open={index >= 0}
              index={index}
              close={() => setIndex(-1)}
              plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
            />
           
          </section>
        </div>
    </>
  )
}


export default Chronicles;
